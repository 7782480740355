<!--工程管理-报表上报，调整到wps在线文档-->
<template>
  <div>
    <div id="forms_div2">
      <div style="margin-left: 2%;margin-bottom: 2%">
        <el-select v-model="document" clearable placeholder="请选择上报报表" size="small" @change="gotoOnlineDocument()">
          <el-option
              v-for="item in documents"
              :key="item.documentName"
              :label="item.documentName"
              :value="item.documentName"
          />
        </el-select>
      </div>
    <!--  根据文档名称查询表格-->
      <div style="width: 95%;margin: auto">
        <el-table :data="online_document_list" stripe style="width: 100%">
          <el-table-column prop="documentName" label="文档名称" />
          <el-table-column prop="id" label="所用工区"  />
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <el-button link type="primary" size="small" @click="getOnlineDocumentUrl(scope.row.documentUrl)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";
import {Decrypt, Encrypt} from '@/utils/secret';

export default {
  components: {Header},
  name: "online_docu_manage",
  data(){
    return{
      document: '',
      documents: [],
      online_document_list: []
    }
  },
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
    if (sessionStorage.getItem('document_name')){
      this.document = sessionStorage.getItem('document_name');
    }
    this.getOnlineDocument();
    this.gotoOnlineDocument();
  },
  methods:{
    getOnlineDocument(){
      this.axios.post('/onlineDocument/getAllOnlineDocuByDepart', (response) => {
        this.documents = response.obj;
      },{
        department_id: 7
      })
    },
    gotoOnlineDocument(){
      sessionStorage.setItem('document_name', this.document);
      this.axios.post('/onlineDocument/getDocuByNameDepart', (response) => {
        this.online_document_list = response.obj;
      },{
        department_id: 7,
        document_name: this.document
      })
    },
    getOnlineDocumentUrl(url){
      const documet_url = Decrypt(url);
      console.log(documet_url)
      this.$router.push({
        name: 'View_online',
        params: {
          online_document_url: documet_url,
          flag: 3
        }
      });
    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>
