<template>
  <div>
  <!--  cookie-->
    <div>
      <el-input
          v-model="cookie"
          autosize
          type="textarea"
          placeholder="请输入Cookie"
      />
      <div>
        <el-button @click="getSyncDate">查询数据</el-button>
      </div>
    </div>
  <!--  数据-->
    <div>
      <div v-for="(item, index) in dates" :key="index" style="margin-top: 2%">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      cookie: '',
      dates: []
    }
  },
  created() {

  },
  methods: {
    getSyncDate(){
      this.axios.post('/synRemp/getDayNum', (response) => {
        this.dates = response.obj
      },{
        cookie: this.cookie
      })
    }
  }
}
</script>

<style scoped>

</style>
