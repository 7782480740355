<template>
  <div>
    <el-date-picker
      v-model="sync_time"
      type="date"
      placeholder="Pick a Date"
      format="YYYY-MM-DD"
      value-format="YYYY-MM-DD"
      @change="get_date"
  />
    <el-button style="margin-left: 1%" @click="sync_date" type="danger">同步</el-button>
    <el-button @click="sync_safe_bj" type="warning">计算安全步距</el-button>
    <el-button type="primary"><a style="text-decoration:none;color: white" :href="url"> 导出数据</a></el-button>
  <!--  数据列表-->
    <div style="margin-top: 2%;width: 100%">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="oper_datas"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
          :span-method="mergeRowMethod"
      >
        <vxe-column type="seq" title="序号" width="3%"></vxe-column>
        <vxe-column field="bdst" title="工区" width="7%"></vxe-column>
        <vxe-column field="operName" title="工点" width="15%"></vxe-column>
        <vxe-column field="stockLevel" title="围岩级别" width="5%"></vxe-column>
        <vxe-column field="body" title="部位" width="5%"></vxe-column>
        <vxe-column field="dayMile" title="今日里程" width="10%"></vxe-column>
        <vxe-column field="num" title="今日进尺" width="5%"></vxe-column>
        <vxe-column field="monthNum" title="月累进尺" width="5%"></vxe-column>
        <vxe-column field="allNum" title="开累进尺" width="10%"></vxe-column>
        <vxe-column field="safe" title="安全步距" width="10%">
          <template #default="{ row }">
            <span>{{ row.safeBj }} / {{ row.safeMax }}</span>
          </template>
        </vxe-column>
        <vxe-column field="remarks" title="备注" width="25%"></vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {setNowDate, setNowTimes} from "@/utils/time";

export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      sync_time: '',
      oper_datas: [],
      allAlign: 'center',
      url: 'https://www.gjegm.ltd/czsczq/onlineDayProgress/wpsLeadOut?date=' + setNowDate(new Date()),
      url1: 'http://localhost:8082/onlineDayProgress/wpsLeadOut?date=' + setNowDate(new Date()),
    }
  },
  created() {
    this.sync_time = setNowDate(new Date())
    this.get_date()
    //this.sync_date()
  },
  methods: {
    sync_date(){
      this.axios.get('/operDispose/syncDate', (response) => {
        this.get_date()
      },{
        time: this.sync_time
      })
    },
    sync_safe_bj(){
      this.axios.get('/onlineDayProgress/safeBj', (response) => {
        this.get_date()
      },{
        time: this.sync_time
      })
    },
    get_date(){
      this.axios.get('/onlineDayProgress/getSyncData', (response) => {
        this.oper_datas = response.obj
        this.url = 'https://www.gjegm.ltd/czsczq/onlineDayProgress/wpsLeadOut?date=' + this.sync_time
      },{
        time: this.sync_time
      })
    },
    mergeRowMethod({row, _rowIndex, column, visibleData}) {
      const fields = ['bdst', 'operName', 'stockLevel']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
